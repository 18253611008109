export const walletSwitchChain = (chainId) => {
  chainId = '0x' + (+chainId).toString(16)
  return new Promise((resolve, reject) => {
    if (window.ethereum) {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      }).then(() => {
          console.log('网络切换成功')
          resolve()
        })
        .catch(async (e) => {
          console.log(e)
          reject(e)
        })
    }
  })
}
