<template>
  <div id="app" class="content" ref="content" @mousewheel="mouseWheel">
    <Section1 @claim="claim" @connectWallet="connectWallet" @addToken="addToken" :walletAddress="walletAddress" class="section-item" />
    <Section2 class="section-item" />
    <div v-if="isShowDialog" class="dialog" @mousewheel.stop>
      <div class="dialog-content">
        <div class="dialog-msg" :style="{'font-size': msg.length > 30 ? 'calc((28 / 1920) * 100vw)' : 'calc((38 / 1920) * 100vw)'}" v-html="msg" @click.stop="changeNetwork($event)"></div>
        <img src="@/assets/images/close.png" alt="" class="close" @click="() => {isShowDialog = false; msg = ''}">
      </div>
    </div>
  </div>
</template>

<script>
import { connectWallet, contract, addToken } from "./utils/connectWallet";
import { walletSwitchChain } from './utils/walletSwitchChain'
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { config } from "./lib/config"
import { contractData } from "./lib/config"
import { ethers } from 'ethers'
import Section1 from './components/Section1.vue'
import Section2 from './components/Section2.vue'
export default {
  name: "App",
  components: {
    Section1,
    Section2,
  },
  data() {
    return {
      web3: null,
      contract: null,
      merkleTree: null,
      contractAddress: contractData.address,
      abi: contractData.abi,
      walletAddress: '',
      pageIndex: 0,
      pageScroll: true,
      prevIndex: 0,
      sectionLength: 0,
      content: null,
      msg: '',
      isShowDialog: false
    };
  },
  async mounted() {
    this.content = this.$refs.content
    this.sectionLength = document.querySelectorAll('.section-item').length
    window.ethereum.on("chainChanged", (chainId) => {
      console.log("用户切换了链", chainId)
      this.msg = ''
      this.isShowDialog = false
      this.connectWallet()
    })
    window.ethereum.on("accountsChanged", (chainId) => {
      console.log("切换账号", chainId)
      this.msg = ''
      this.isShowDialog = false
      this.connectWallet()
    })
  },
  methods: {
    claim() {
      if (this.web3) {
        this.main()
      } else {
        this.connectWallet('claim')
      }
    },
    changeNetwork(e) {
      console.log(e.target.nodeName)
      if (e.target.nodeName.toLocaleLowerCase() == 'p') {
        walletSwitchChain(1).then(res => {
          console.log(res)
        })
      }
    },
    async addToken() {
      if (this.web3 && this.contract) {
        let network = await this.web3.getNetwork()
        if (network.chainId != 1) {
          this.isShowDialog = true
          this.msg = `Sorry, maybe the wallet is on the wrong network? <p style="cursor: pointer;margin-top: 12px;text-decoration: underline;">click here change</p>`
          return
        }
        let address
        try {
          address = await this.contract.TokenAddress()
        } catch (error) {
          this.isShowDialog = true
          if (error.error && error.error.message) this.msg = 'Error: ' + error.error.message
          else this.msg = 'Error: ' + error
          return
        }
        addToken(address, 'MT').then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.connectWallet('addToken')
      }
    },
    connectWallet(type) {
      connectWallet().then(async res => {
        console.log(res)
        this.web3 = res
        let user = this.web3.getSigner()
        this.walletAddress = await user.getAddress()
        this.contract = await contract(this.contractAddress, this.abi, user)
        if (type && type == 'claim') {
          this.main()
        } else if (type && type == 'addToken') {
          this.addToken()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    generateLeaf(address, value) {
      return Buffer.from(
        ethers.utils.solidityKeccak256(["address", "uint256"], [address, value]).slice(2), "hex"
      )
    },
    async main() {
      if (!config.airdrop[this.walletAddress]) {
        this.isShowDialog = true
        this.msg = 'Sorry, your address is not qualified for the claim.'
        return
      }
      let network = await this.web3.getNetwork()
      if (network.chainId != 1) {
        this.isShowDialog = true
        this.msg = `Sorry, maybe the wallet is on the wrong network? <p style="cursor: pointer;margin-top: 12px;text-decoration: underline;">click here change</p>`
        return
      }
      this.merkleTree = new MerkleTree(
        Object.entries(config.airdrop).map(([address, tokens]) =>
          this.generateLeaf(
            ethers.utils.getAddress(address),
            ethers.utils.parseUnits(tokens.toString(), config.decimals).toString()
          )
        ),
        keccak256, { sortPairs: true }
      );
      const formattedAddress = ethers.utils.getAddress(this.walletAddress)
      const numTokens = ethers.utils.parseUnits(config.airdrop[this.walletAddress].toString(), config.decimals).toString()
      const leaf = this.generateLeaf(formattedAddress, numTokens)
      // let index = this.merkleTree.leaves.length
      const proof = await this.merkleTree.getHexProof(leaf);
      console.log(this.walletAddress, numTokens, proof)
      let tx
      try {
        tx = await this.contract.claim(this.walletAddress, numTokens, proof, { from: this.walletAddress })
      } catch (error) {
        this.isShowDialog = true
        if (error.error && error.error.message) this.msg = 'Error: ' + error.error.message
        else this.msg = 'Error: ' + error
        return
      }
      console.log(tx)
      if (tx && tx.hash) {
        this.isShowDialog = true
        this.msg = 'Claim successful.'
        setTimeout(() => {
          this.isShowDialog = false
          this.msg = ''
        }, 10000)
      }
    },

    mouseWheel(e) {
      if (e.wheelDelta) {
        if (e.wheelDelta > 0) {
          this.scrollUp()
        } else {
          this.scrollDown()
        }
      } else {
        if (e.detail > 0) {
          this.scrollDown()
        } else {
          this.scrollUp()
        }
      }
    },

    scrollUp() {
      if (this.pageIndex > 0 && this.pageScroll) {
        this.prevIndex = this.pageIndex
        this.pageIndex --
        this.srcollToPage(this.pageIndex, this.prevIndex)
      } else if (this.pageIndex <= 0) {
        this.pageIndex = 0
      }
    },
    
    scrollDown() {
      if (this.pageIndex < this.sectionLength - 1 && this.pageScroll) {
        this.pageIndex ++
        this.srcollToPage(this.pageIndex)
      } else if (this.pageIndex >= this.sectionLength.length - 1) {
        this.pageIndex = this.sectionLength.length - 1
      }
    },
    srcollToPage(pageIndex) {
      this.content.style.top = - pageIndex * 100 + "vh";
      this.pageScroll = false
      this.scrollTimer()
    },
    scrollTimer() {
      setTimeout(() => {
        this.pageScroll = true
      }, 500)
    }
  },
};
</script>

<style lang="scss">
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.content {
  position: relative;
  transition: all .5s;
  top: 0;
}
.section {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  .bg-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .section-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
.dialog {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
  .dialog-content {
    position: absolute;
    left: calc((894 / 1920) * 100vw);
    top: calc((488 / 1080) * 100vh);
    width: calc((700 / 1920) * 100vw);
    height: calc((298 / 1920) * 100vw);
    min-width: 350px;
    min-height: 149px;
    background: url(./assets/images/dialog_bg.png) no-repeat;
    background-size: 100% 100%;
    .dialog-msg {
      margin: auto;
      margin-top: calc((106 / 1920) * 100vw);
      font-size: calc((38 / 1920) * 100vw);
      font-family: ArialMT;
      color: #CCBFFF;
      text-align: center;
      line-height: 1;
    }
    .close {
      position: absolute;
      right: 14px;
      top: 14px;
      width: calc((50 / 1920) * 100vw);
      height: calc((50 / 1920) * 100vw);
      min-width: 25px;
      min-height: 25px;
      cursor: pointer;
    }
  }
}
@media (max-aspect-ratio: 1920/1080) {
  .section {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    .bg-img {
      height: 100%;
    }
  }
}
</style>
