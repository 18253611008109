<template>
  <div class="section">
    <img src="@/assets/images/section1_bg.png" alt="" class="bg-img" />
    <div class="hd">
      <div class="add-mt" @click="addToken">Add $MT</div>
      <div :class="['wallet', address ? 'wallet-addr' : '']" @click="connectWallet">
        <img src="@/assets/images/wallet.svg" alt="">
        <span v-if="address">{{address | addressFormat}}</span>
        <span v-else>CONNECT WALLET</span>
      </div>
    </div>
    <div class="section-content">
      <div class="section-main">
        <div class="section-title">Mental Token</div>
        <div class="section-desc">
          Mental is a revolutionary Web 3 product that is powered by VR and intended to reflect potential thoughts inside the human brain into the real world. Mental Token ($MT) will be the exclusive approach to participation.
        </div>
        <div class="section-desc">Free Claim 100 $MT:</div>
        <div class="btn" @click="claim">CLAIM</div>
      </div>
    </div>
  </div>
</template>

<script>
// import { config } from "../lib/config"

export default {
  name: 'Section1',
  props: ['walletAddress'],
  filters: {
    addressFormat(addr = '') {
      return `${addr.slice(0, 7)}......${addr.slice(-6)}`;
    }
  },
  watch: {
    walletAddress(val) {
      this.address = val
      console.log(this.address)
    }
  },
  data() {
    return {
      address: this.walletAddress || ''
    }
  },
  methods: {
    claim() {
      this.$emit('claim')
    },
    connectWallet() {
      if (!this.walletAddress) {
        this.$emit('connectWallet')
      }
    },
    addToken() {
      this.$emit('addToken')
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.section {
  .hd {
    position: absolute;
    left: calc((1068 / 1920) * 100vw);
    top: calc((50 / 1080) * 100vh);
    display: flex;
    align-items: center;
    z-index: 999;
    .add-mt {
      width: calc((164 / 1920) * 100vw);
      height: calc((63 / 1920) * 100vw);
      min-width: 82px;
      min-height: 32px;
      background: url(../assets/images/add_bg.png) no-repeat;
      background-size: 100% 100%;
      font-size: calc((26 / 1920) * 100vw);
      font-family: ArialMT;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .wallet {
      margin-left: 12px;
      width: calc((322 / 1920) * 100vw);
      height: calc((63 / 1920) * 100vw);
      min-width: 161px;
      min-height: 32px;
      background: url(../assets/images/cw_bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      padding-left: calc((22 / 1920) * 100vw);
      box-sizing: border-box;
      cursor: pointer;
      &.wallet-addr {
        background-image: url(../assets/images/address_bg.png);
      }
      img {
        width: calc((30 / 1920) * 100vw);
        height: auto;
      }
      span {
        margin-left: calc((19 / 1920) * 100vw);
        font-size: calc((24 / 1920) * 100vw);
        font-family: ArialMT;
        color: #FFFFFF;
        margin-top: 3px;
      }
    }
  }
  .section-content {
    .section-main {
      position: absolute;
      left: calc((1002 / 1920) * 100vw);
      top: calc((260 / 1080) * 100vh);
      .section-title {
        font-size: calc((90 / 1920) * 100vw);
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 1.2;
      }
      .section-desc {
        margin-top: calc((38 / 1080) * 100vh);
        width: calc((567 / 1920) * 100vw);
        min-width: 180px;
        font-size: calc((28 / 1920) * 100vw);
        line-height: 1.5;
        font-family: ArialMT;
        color: #FFFFFF;
      }
      .btn {
        margin: auto;
        margin-top: calc((73 / 1080) * 100vh);
        width: calc((297 / 1920) * 100vw);
        height: calc((89 / 1920) * 100vw);
        min-width: 90px;
        min-height: 26px;
        background: url(../assets/images/btn_bg.png) no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc((66 / 1920) * 100vw);
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
}
</style>
